import { ethers, Contract, JsonRpcProvider } from "ethers";
import abi from "./vest.json";
import sale from "./sale.json";

export async function ReadContract() {
 const contractAddress = "0x152947cb452348C4AD908195c643c337482b6290";

 //const contractAddress = "0x40c7696E2B89f551e288322E52b22c2Fa6B319E8";
 //const provider = ethers.getDefaultProvider('https://bsc-dataseed.binance.org/');

 const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

 //const provider = new JsonRpcProvider("https://data-seed-prebsc-1-s1.binance.org:8545");

 const contract = new Contract(contractAddress, abi, provider);
 // console.log("CONTRACTA", contract);
 return contract;
}

export async function ReadSale2Contract() {
 const contractAddress = "0xFFCF35B34a59d787D3fdaDc45E4064558EDA6f2f";

 const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

 const contract = new Contract(contractAddress, sale, provider);
 return contract;
}

export async function ReadSale1Contract() {
 const contractAddress = "0x6c206A495611B9D0b4C2b70316738cAeB01a69Cc";

 const provider = new JsonRpcProvider("https://bsc-dataseed.binance.org/");

 const contract = new Contract(contractAddress, sale, provider);
 return contract;
}
