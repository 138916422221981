import logo from "./logo.png";
import bg from "./bg.jpg";
import about from "./about.jpeg";
import faq from "./faq.jpeg";

//import bg from "../public/bg.jpg";
import abi from "./utils/vest.json";
import tokenAbi from "./utils/token.json";

import "./App.css";
import { useState, useEffect } from "react";
import { ethers } from "ethers";
//98224d4e1aa436d5b8fea301e0ff3c53
import { useWeb3ModalProvider, useWeb3ModalAccount } from "@web3modal/ethers/react";
import { BrowserProvider, Contract, formatUnits, parseUnits } from "ethers";
//import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5/react";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers/react";
import CountdownTimer from "./Countdown/CountdownTimer";
import { ReadContract } from "./utils/readContract";
import { Allowance } from "./utils/allowance";
import { message } from "antd";
import Spinner from "react-spinkit";
import Vested from "./Vested";
import RoundOneContributions from "./RoundOne";

// 1. Get projectId
const projectId = "98224d4e1aa436d5b8fea301e0ff3c53";
//const token = "0x6c206A495611B9D0b4C2b70316738cAeB01a69Cc";
const token = "0x55d398326f99059fF775485246999027B3197955";
//const token = "0xaC8A6B542DEe616a90C06FD0f490412B96618cBB";
//const ca = "0x40c7696E2B89f551e288322E52b22c2Fa6B319E8";
//TEST;
const ca = "0x152947cb452348C4AD908195c643c337482b6290";
const time = Date.now();
//LAST 0xae287547Cf6E8eF3153a920e5e7744961ad9E384
// 2. Set chains
const mainnet = {
 chainId: 1,
 name: "Ethereum",
 currency: "ETH",
 explorerUrl: "https://etherscan.io",
 rpcUrl: "https://cloudflare-eth.com",
};

const bscTestnet = {
 chainId: 97,
 name: "Binance Smart Chain Testnet",
 currency: "BNB",
 explorerUrl: "https://testnet.bscscan.com",
 rpcUrl: "https://data-seed-prebsc-1-s1.binance.org:8545",
};

const bscMainnet = {
 chainId: 56,
 name: "Binance Smart Chain",
 currency: "BNB",
 explorerUrl: "https://bscscan.com",
 rpcUrl: "https://bsc-dataseed1.binance.org",
};

// 3. Create a metadata object
const metadata = {
 name: "18plus",
 description: "The first real adult coin",
 url: "https://modeland.ai/", // origin must match your domain & subdomain
 icons: ["https://modeland.ai/logo.jpg"],
};

// 4. Create Ethers config
const ethersConfig = defaultConfig({
 /*Required*/
 metadata,

 /*Optional*/
 enableEIP6963: true, // true by default
 enableInjected: true, // true by default
 enableCoinbase: true, // true by default
 rpcUrl: "https://bsc-dataseed1.binance.org", // used for the Coinbase SDK
 defaultChainId: 56, // used for the Coinbase SDK
});

// 5. Create a Web3Modal instance
createWeb3Modal({
 ethersConfig,
 chains: [bscMainnet],
 projectId,
 enableAnalytics: true, // Optional - defaults to your Cloud configuration
});

function Presale() {
 const [currentBalance, setCurrentBalance] = useState(0);
 const [claimedPeriods, setClaimedPeriods] = useState(0);
 const [claimableTokens, setClaimableTokens] = useState(0);
 const [initialBalance, setInitialBalance] = useState(0);
 const [lastClaimTime, setLastClaimTime] = useState(0);
 const [wallet, setWallet] = useState("");
 const [vestInfo, setVestInfo] = useState({});
 const [openingTime, setOpeningTime] = useState("");
 const [closingTime, setClosingTime] = useState("");
 const [amount, setAmount] = useState("");
 const [loading, setLoading] = useState(false);
 const [maxBuy, setMaxBuy] = useState("");
 const [minBuy, setMinBuy] = useState("");
 const [perCoin, setPerCoin] = useState("");
 const [hasEnded, setHasEnded] = useState(false);
 const [canClaim, setCanClaim] = useState(false);

 const [contribution, setContribution] = useState("");

 const { address, chainId, isConnected } = useWeb3ModalAccount();
 const { walletProvider } = useWeb3ModalProvider();

 const getContribution = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.contributions(account);
   // console.log("WEI", wei);
   setContribution(formatUnits(wei.toString(), 18));
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getContribution(address);
 }, [address]);

 const getBalance = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.balances(account);
   // console.log("WEI", wei);
   setCurrentBalance(formatUnits(wei.toString(), 18));
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getBalance(address);
 }, [address]);

 const getWBalance = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.balances(account);
   // console.log("WEI", wei);
   return formatUnits(wei.toString(), 18);
  } catch (err) {
   console.log(err);
  }
 };

 const getInitialBalance = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.initialBalances(account);
   // console.log("WEI", wei);
   setInitialBalance(formatUnits(wei.toString(), 18));
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getInitialBalance(address);
 }, [address]);

 const getWInitialBalance = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.initialBalances(account);
   // console.log("WEI", wei);
   return formatUnits(wei.toString(), 18);
  } catch (err) {
   console.log(err);
  }
 };

 const getClaimableTokens = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.getClaimableTokens(account);
   // console.log("WEI", wei);
   setClaimableTokens(formatUnits(wei.toString(), 18));
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getClaimableTokens(address);
 }, [address]);

 const getWClaimableTokens = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.getClaimableTokens(account);
   // console.log("WEI", wei);
   return formatUnits(wei.toString(), 18);
  } catch (err) {
   console.log(err);
  }
 };

 const getClaimedPeriods = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.claimedPeriods(account);
   // console.log("WEI", wei);
   setClaimedPeriods(wei.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getClaimedPeriods(address);
 }, [address]);

 const getLastClaimTime = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.lastClaimTime(account);
   // console.log("WEI", wei);
   setLastClaimTime(wei.toString());
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getLastClaimTime(address);
 }, [address]);

 const getWLastClaimTime = async (account) => {
  if (!account) return;
  try {
   const contract = await ReadContract();

   const wei = await contract.lastClaimTime(account);
   // console.log("WEI", wei);
   return wei.toString();
  } catch (err) {
   console.log(err);
  }
 };

 const canWithdraw = async () => {
  try {
   const contract = await ReadContract();

   const time = await contract.canWithdraw();
   //console.log("ENDED", time);

   setCanClaim(time);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  canWithdraw();
 }, []);

 function formatNumber(number) {
  return Number(number).toLocaleString("en-US", {
   minimumFractionDigits: 2,
   maximumFractionDigits: 2,
  });
 }

 const handleClaimToken = async () => {
  if (!isConnected) return;
  try {
   // console.log('checkpoint', isTrustWallet)
   let contract;
   setLoading(true);
   const provider = new BrowserProvider(walletProvider);
   const signer = await provider.getSigner();
   contract = new Contract(ca, abi, signer);
   const data = await contract.claimTokens({ from: address });
   //
   async function Pending() {
    const provider = new BrowserProvider(walletProvider);
    const result = await provider.getTransactionReceipt(data.hash);
    try {
     if (result === null) {
      setTimeout(() => {
       Pending();
      }, 2000);
     } else if (result !== null) {
      await getBalance(address);
      await getClaimableTokens(address);
      await getClaimedPeriods(address);
      await getLastClaimTime(address);
      alert("Your tokens were sent to your wallet.");
      setLoading(false);
     }
    } catch (error) {
     //  console.log("TX ERROR", error);
     setLoading(false);
    }
   }

   setTimeout(() => {
    Pending();
   }, 2000);
  } catch (error) {
   //  console.log("PURCHASE ERROR", error);
   let errorMessage;
   if (error.code === 4001) {
    errorMessage = "The request was rejected!";
   } else {
    errorMessage = error.message.split('"')[1];
   }
   alert(errorMessage);
   setLoading(false);
  }
 };

 const fetchVesting = async (wallet) => {
  if (!wallet) return alert("Please paste a wallet first.");
  setVestInfo({});
  setLoading(true);
  try {
   // Run tasks sequentially
   const initialBalance = await getWInitialBalance(wallet);
   const currentBalance = await getWBalance(wallet);
   const claimableTokens = await getWClaimableTokens(wallet);
   const lastClaimTime = await getWLastClaimTime(wallet);

   const results = {
    wallet,
    initialBalance: initialBalance.toString() || 0,
    currentBalance,
    claimableTokens,
    lastClaimTime,
   };
   setVestInfo(results);
   console.log("RESULT", results);
  } catch (error) {
   console.error("Error fetching contributions:", error);
  } finally {
   setLoading(false);
  }
 };

 const round2 = [
  "0x1E519358403D1F696bFE7298D9C45B94BFFF1f8D",
  "0x46900359EaDfA788f06B27d51F66C1B8e81F7556",
  "0xA37E3b84417A672F2f00e5061635FD70D83269fd",
  "0xFB4C53E69aa3dfEF9C477660fd82735C16481f59",
  "0x41dF4fDB6f1A6Fd975b3c2C6E692D46e7218E5dF",
  "0x86f2888c3983fD93bcbE616D5E252f82546dAAC0",
  "0xae287547Cf6E8eF3153a920e5e7744961ad9E384",
  "0x2443e849e726EF873938c55C3E4bcd2c5278216a",
  "0xa4233bC022F9D197fdB74886E585eAa43E2D5Ad5",
  "0xA4D46A5af985b142A547F4d5cc97CC2f06bAab0e",
  "0x714C6EcA8E9aaa5aD1e9938876c986d355de85da",
  "0xF2D15DE012A44555B685c4B8DE68423413b75bD9",
  "0x365166f14C6ED9E3a842da93aAA1Cb903764B05a",
  "0x14357C405ec998c537846a3bF43d362091857403",
  "0xE1c8a69E1E8a80eCFBE0982819d953Fb08836fc4",
  "0x616cC09ed175aD1820edf15EDc8Fa7aa53223229",
  "0xa652026889B3D9418f1257D18B54E73338A57620",
  "0xB8054f466b62fa1d495d56581627227D5877C53f",
  "0xe858fEDee9724086cD746c01E484cB1553e3bE5b",
  "0x6DE5e8583d336A7a7Eee4EF2417CaF043DE84170",
  "0xb25b05Bd45bff60FEd23C4E50e5B689305Df0514",
  "0x336A74BDD2350E4632A0BFF919C5F783C0519Cd2",
  "0xd556387f0BE504323d8538fD2A92a96dcCabf420",
  "0xA234AD261e994f7837b4fB6eeD7f67aB53981389",
  "0x4c8032e4EE5f72AacB72C122E6353F4266Ac6888",
  "0x0B6215585Fd3a591E1B84a80B9f849944cBE91A1",
  "0x646C824E2a9aa4b61Ad8eD41448189193Fe7d846",
  "0x7EAa8afE09501Bf2A0578f535ce8866451750CF4",
  "0xD0bd53a785aF63Dbf199835803Da372A405CEBff",
  "0x1BEc672Aa1F37ef790812068F95a75774e0FE749",
  "0x4b59F58D47A5948E2C5E448Da40824F2Ac3F82a2",
  "0x9E792Ea818c6db4aAEB832eBE2CA03d34BD18F3b",
  "0x7aA056D3f88b7c84bB7822A5f7a38daEF8cC6528",
  "0x707442a72d8ed930434FccB0deD2287d8C968368",
  "0x2F0B9E271e99d9A1b782fb289d84bbaBFb9a129B",
 ];

 const round1 = [
  "0x263157b41dabf088b176c23731ef088df39628d5",
  "0x951b02e4f1ed23cac38ca58621ed6e41195df946",
  "0x16f534c410fe78580d199e0d9e0574e7e21a46d9",
  "0x2a3eaba61b3c7a049b190bff8827c55134d96e91",
  "0xe34146a673b5fe443e8f679230f098421a177207",
  "0x5efa10c73a628658a5b262a6ed396aff267cdad2",
  "0xa98378ddd2e019620a60d858a0794cfdc8386ac4",
  "0x6d22cf1460884d2cdcf7c0d02f8babf3b21376e6",
  "0x1473ef68f069659138878beb2f8c62af3400dcdd",
  "0x0764df1177b05f9243f4c88b4f7bfc35a96ef6cc",
  "0x60d15e4436b9a20f70eda5d4ee7809a5c9f3a48f",
  "0x5e88dc6d3dbf0801f1b7ed72bf4fa641ac2b5c80",
  "0xa25f1a2ba9eacc59b6100c1b8b305a746c09cfb9",
  "0x67113cbb381f6da062744c38f2fd0f6eb5d1faf5",
  "0x2d595b30e143c101aa4ed225bdf7020afc35f06e",
  "0xddc4fc0fc522fc44b642b741543e87273a8a03fc",
  "0x405eaa02c0dd73ba95b0ee11d181cc0b8551a23e",
  "0x7b285e4611fbfc46df89a9fb0fdd68b9576366b5",
  "0x802bf5663f25065c1038edf60e0ab98572722b4b",
  "0x0346e736851fd19da5c7af9aee0e103e83e75322",
  "0x927984747398c9c7a0d4ce91408a6888974c7cf4",
  "0x5c7f8d920402a06407df2738a3c01df5e7382056",
  "0x292101413f51836e50c3f9e9126e6608e68a252e",
  "0x5c69cd5d2b3a8c02e61a1fe735350a97749db00e",
  "0x9b6526a9794386b3ea38ffe8a004ac04da079875",
  "0xe1c8a69e1e8a80ecfbe0982819d953fb08836fc4",
  "0xdc244d36af1bd7f5a1c3800a4b8af6d382dc30a6",
  "0xfbdcace10c8a1f9dd7703f4f5276b36c49037bfc",
  "0x7c57509b975424a17f64cb2bcda4026eaeca11c5",
  "0x02a4d96f51a33084aa3ffa54799da99f5f11224c",
  "0x5b1398841d3d8fb9f4350bc24c065f7bda27e350",
  "0x263157b41dabf088b176c23731ef088df39628d5",
  "0x927984747398c9c7a0d4ce91408a6888974c7cf4",
  "0xee6255e6632c7ea6534bd8acf9493bf22e6c77f3",
  "0x8b0065af0280f1e640c4a3671d3848b699853870",
  "0xc69a92572cce6846f72ae3f7f6390b531a16d8c7",
  "0x3c71e8aac5fc9c0759cccf48bb0e0b5f9ef5d1d0",
  "0x5c69cd5d2b3a8c02e61a1fe735350a97749db00e",
  "0xabc619fd4ff79c6e61de65cff8df51753f62d549",
  "0xa738d6ee452e86e9f9635772ddb2740f9f2a7e0f",
  "0xa085788c104af49e8b67b032b8a9bedb16139097",
  "0x7c57509b975424a17f64cb2bcda4026eaeca11c5",
  "0x251d29fafe1f22c1597531700c1c927bb53e0c0c",
  "0xb25b05bd45bff60fed23c4e50e5b689305df0514",
  "0xae287547cf6e8ef3153a920e5e7744961ad9e384",
  "0x16145ad552eb7ce6b078389b60530f4cfd33271b",
  "0x536b67d1867813b2443ac27f4408f35c08703e33",
  "0x181228648fc3172238e8d05ddec73dc7ed7eca92",
  "0x23400361f5d4d7c677be9fa2141212d6bfdc38f8",
  "0x1cdfb1be2d258294361179e3f8781c44abb3aaea",
  "0x640ded088c6597009d7f8e9008e0e54d59a64085",
  "0x252b2e0a5d628f300d1fd538a5caf68ecba3b846",
  "0xa70a0aa99c4ad78ec4e464517917c0154ac380d4",
  "0x33c76ddc2c60b9b5ee47caf938d75379c294897a",
  "0x40b938dddd645ae9a0eb048fc65cf1709b271621",
  "0xbd70512c5a10c6f658e1682815bd8348b66c59c1",
  "0x9c51f8f81a893088638af7333e72d34903ec3c6b",
  "0x12a351d847af676036ae5e6506f85af13526d19f",
  "0x52bc37833a7e436ff95f96395a4c20ea20397b03",
  "0x81de6241e8daaae50131377b594c0100922cfd36",
  "0x9a4be97f396ad542b02faf9c331f2b01faaad356",
  "0xfcc8285219cd7e95a74381f66d33c1bf18b8c007",
  "0x3d208d41a24d658349d384ce8245cda3cc29399c",
  "0x3bc4790619db1d6118f4e148611f1ba42d7bacd7",
  "0xcb785f586d180c959aee1b097a286ec7ffb37607",
  "0xd4cad8ddba163c358f38f821c4eefceeb4523d15",
  "0x0346e736851fd19da5c7af9aee0e103e83e75322",
  "0x536b67d1867813b2443ac27f4408f35c08703e33",
  "0x9e667a0d05f00b715b6e67d2099844d6df05454b",
  "0xd355390ad8c9c4489929bcb16671db989f577182",
  "0x07a2aff6c9639ebe9c16b4248ea6508a488feac6",
  "0xe3e787b1848a58daf46bceb71aa643e1a2ff183a",
 ];

 //VESTED
 const vested = [
  "0x263157b41dabf088b176c23731ef088df39628d5",
  "0x951b02e4f1ed23cac38ca58621ed6e41195df946",
  "0x16f534c410fe78580d199e0d9e0574e7e21a46d9",
  "0x2a3eaba61b3c7a049b190bff8827c55134d96e91",
  "0xe34146a673b5fe443e8f679230f098421a177207",
  "0x5efa10c73a628658a5b262a6ed396aff267cdad2",
  "0xa98378ddd2e019620a60d858a0794cfdc8386ac4",
  "0x6d22cf1460884d2cdcf7c0d02f8babf3b21376e6",
  "0x1473ef68f069659138878beb2f8c62af3400dcdd",
  "0x0764df1177b05f9243f4c88b4f7bfc35a96ef6cc",
  "0x60d15e4436b9a20f70eda5d4ee7809a5c9f3a48f",
  "0x5e88dc6d3dbf0801f1b7ed72bf4fa641ac2b5c80",
  "0xa25f1a2ba9eacc59b6100c1b8b305a746c09cfb9",
  "0x67113cbb381f6da062744c38f2fd0f6eb5d1faf5",
  "0x2d595b30e143c101aa4ed225bdf7020afc35f06e",
  "0xddc4fc0fc522fc44b642b741543e87273a8a03fc",
  "0x405eaa02c0dd73ba95b0ee11d181cc0b8551a23e",
  "0x7b285e4611fbfc46df89a9fb0fdd68b9576366b5",
  "0x802bf5663f25065c1038edf60e0ab98572722b4b",
  "0x0346e736851fd19da5c7af9aee0e103e83e75322",
  "0x927984747398c9c7a0d4ce91408a6888974c7cf4",
  "0x5c7f8d920402a06407df2738a3c01df5e7382056",
  "0x292101413f51836e50c3f9e9126e6608e68a252e",
  "0x5c69cd5d2b3a8c02e61a1fe735350a97749db00e",
  "0x9b6526a9794386b3ea38ffe8a004ac04da079875",
  "0xe1c8a69e1e8a80ecfbe0982819d953fb08836fc4",
  "0xdc244d36af1bd7f5a1c3800a4b8af6d382dc30a6",
  "0xfbdcace10c8a1f9dd7703f4f5276b36c49037bfc",
  "0x7c57509b975424a17f64cb2bcda4026eaeca11c5",
  "0x02a4d96f51a33084aa3ffa54799da99f5f11224c",
  "0x5b1398841d3d8fb9f4350bc24c065f7bda27e350",
  "0x263157b41dabf088b176c23731ef088df39628d5",
  "0x927984747398c9c7a0d4ce91408a6888974c7cf4",
  "0xee6255e6632c7ea6534bd8acf9493bf22e6c77f3",
  "0x8b0065af0280f1e640c4a3671d3848b699853870",
  "0xc69a92572cce6846f72ae3f7f6390b531a16d8c7",
  "0x3c71e8aac5fc9c0759cccf48bb0e0b5f9ef5d1d0",
  "0x5c69cd5d2b3a8c02e61a1fe735350a97749db00e",
  "0xabc619fd4ff79c6e61de65cff8df51753f62d549",
  "0xa738d6ee452e86e9f9635772ddb2740f9f2a7e0f",
  "0xa085788c104af49e8b67b032b8a9bedb16139097",
  "0x7c57509b975424a17f64cb2bcda4026eaeca11c5",
  "0x251d29fafe1f22c1597531700c1c927bb53e0c0c",
  "0xb25b05bd45bff60fed23c4e50e5b689305df0514",
  "0xae287547cf6e8ef3153a920e5e7744961ad9e384",
  "0x16145ad552eb7ce6b078389b60530f4cfd33271b",
  "0x536b67d1867813b2443ac27f4408f35c08703e33",
  "0x181228648fc3172238e8d05ddec73dc7ed7eca92",
  "0x23400361f5d4d7c677be9fa2141212d6bfdc38f8",
  "0x1cdfb1be2d258294361179e3f8781c44abb3aaea",
  "0x640ded088c6597009d7f8e9008e0e54d59a64085",
  "0x252b2e0a5d628f300d1fd538a5caf68ecba3b846",
  "0xa70a0aa99c4ad78ec4e464517917c0154ac380d4",
  "0x33c76ddc2c60b9b5ee47caf938d75379c294897a",
  "0x40b938dddd645ae9a0eb048fc65cf1709b271621",
  "0xbd70512c5a10c6f658e1682815bd8348b66c59c1",
  "0x9c51f8f81a893088638af7333e72d34903ec3c6b",
  "0x12a351d847af676036ae5e6506f85af13526d19f",
  "0x52bc37833a7e436ff95f96395a4c20ea20397b03",
  "0x81de6241e8daaae50131377b594c0100922cfd36",
  "0x9a4be97f396ad542b02faf9c331f2b01faaad356",
  "0xfcc8285219cd7e95a74381f66d33c1bf18b8c007",
  "0x3d208d41a24d658349d384ce8245cda3cc29399c",
  "0x3bc4790619db1d6118f4e148611f1ba42d7bacd7",
  "0xcb785f586d180c959aee1b097a286ec7ffb37607",
  "0xd4cad8ddba163c358f38f821c4eefceeb4523d15",
  "0x0346e736851fd19da5c7af9aee0e103e83e75322",
  "0x536b67d1867813b2443ac27f4408f35c08703e33",
  "0x9e667a0d05f00b715b6e67d2099844d6df05454b",
  "0xd355390ad8c9c4489929bcb16671db989f577182",
  "0x07a2aff6c9639ebe9c16b4248ea6508a488feac6",
  "0xe3e787b1848a58daf46bceb71aa643e1a2ff183a",
  //ROUND 2
  "0x1E519358403D1F696bFE7298D9C45B94BFFF1f8D",
  "0x46900359EaDfA788f06B27d51F66C1B8e81F7556",
  "0xA37E3b84417A672F2f00e5061635FD70D83269fd",
  "0xFB4C53E69aa3dfEF9C477660fd82735C16481f59",
  "0x41dF4fDB6f1A6Fd975b3c2C6E692D46e7218E5dF",
  "0x86f2888c3983fD93bcbE616D5E252f82546dAAC0",
  "0xae287547Cf6E8eF3153a920e5e7744961ad9E384",
  "0x2443e849e726EF873938c55C3E4bcd2c5278216a",
  "0xa4233bC022F9D197fdB74886E585eAa43E2D5Ad5",
  "0xA4D46A5af985b142A547F4d5cc97CC2f06bAab0e",
  "0x714C6EcA8E9aaa5aD1e9938876c986d355de85da",
  "0xF2D15DE012A44555B685c4B8DE68423413b75bD9",
  "0x365166f14C6ED9E3a842da93aAA1Cb903764B05a",
  "0x14357C405ec998c537846a3bF43d362091857403",
  "0xE1c8a69E1E8a80eCFBE0982819d953Fb08836fc4",
  "0x616cC09ed175aD1820edf15EDc8Fa7aa53223229",
  "0xa652026889B3D9418f1257D18B54E73338A57620",
  "0xB8054f466b62fa1d495d56581627227D5877C53f",
  "0xe858fEDee9724086cD746c01E484cB1553e3bE5b",
  "0x6DE5e8583d336A7a7Eee4EF2417CaF043DE84170",
  "0xb25b05Bd45bff60FEd23C4E50e5B689305Df0514",
  "0x336A74BDD2350E4632A0BFF919C5F783C0519Cd2",
  "0xd556387f0BE504323d8538fD2A92a96dcCabf420",
  "0xA234AD261e994f7837b4fB6eeD7f67aB53981389",
  "0x4c8032e4EE5f72AacB72C122E6353F4266Ac6888",
  "0x0B6215585Fd3a591E1B84a80B9f849944cBE91A1",
  "0x646C824E2a9aa4b61Ad8eD41448189193Fe7d846",
  "0x7EAa8afE09501Bf2A0578f535ce8866451750CF4",
  "0xD0bd53a785aF63Dbf199835803Da372A405CEBff",
  "0x1BEc672Aa1F37ef790812068F95a75774e0FE749",
  "0x4b59F58D47A5948E2C5E448Da40824F2Ac3F82a2",
  "0x9E792Ea818c6db4aAEB832eBE2CA03d34BD18F3b",
  "0x7aA056D3f88b7c84bB7822A5f7a38daEF8cC6528",
  "0x707442a72d8ed930434FccB0deD2287d8C968368",
  "0x2F0B9E271e99d9A1b782fb289d84bbaBFb9a129B",
  //EXTRA WALLETS
  "0x565914129BF4d44f873430eD84e8BD84CF9Bf3Ed",
  "0x9D4b926B8456d62B004949A698AA70887A41f582",
  "0x7761DDccAAC2AaDA9D36A775eA367bc9B44c9702",
  "0xAbeA6Ce5AaA6Ce6dC5248A4b1578bb9586d1679e",
  "0xD7A781C49D7Ceb77999928F332ca992Dc795c8e6",
  "0xDFe8dE191ef7ED45140d0969494Ff53660A956b3",
  "0xF94891FffFaD6d734fA9688be4B5bC696edB3270",
  "0x964123536f267C24C0772d382A5a5Ef3057F9d49",
  "0x2f042DD6460A084514f7c183D0DED75F9c5eA29E",
  "0x2A8cae6ec3543807F8454e48463482Ba0d18Fa0E",
  "0x6FEE861701d3B00AE751626e344b5FC1BeD586ab",
  "0xfdfEB11189d0B93B44f77998c41d127c64d1DAaA",
  "0xe858fEDee9724086cD746c01E484cB1553e3bE5b",
  "0xbbCB8c1d17d02f5a10f4C5D5e06EcB8f9cA7A6c0",
  "0x40072dE3E8C051A9a309766C2029815D76256048",
  "0x6d2A565d2c3fD22897c1776371d05E13Cb674eB8",
  "0x847f1f351707C7b0fA9dB1Ca8F6309407b7e31cC",
  "0x88A9E063d11774D0e5782D8eCA4d7Aa84F881A4B",
  "0x39f6C0b5185Ef6d179f5647dcAc3257be0A3Fb2F",
  "0xED2af024dF827fA0f48530Db14E790A10B3e8498",
  "0x46CB98fDdb80997b11344304067ddF31704d5403",
  "0xF2D15DE012A44555B685c488DE68423413b75bD9",
  "0x483Fbd91CAd3BA156B62A987981B4a0585619681",
  "0x6e43333805d8e1377f8ddf8fF866152DDCBc223D",
  "0x736B5ef3380E6a9Ea49D5169Fa0F751961EA3E02",
  "0x874B870634c4fCD2d9a1bAC62bF1fbbEeF48471E",
  "0xAEe34f3FC0d8e00afeFD472DD0D282bdB133878E",
  "0x1D3542a0c5076305082772dfD54CA8e284c8B111",
  "0xC0e44d729d601c7D549a6Edb79F802FeE747b8C5",
  "0x2bcfC5B79dD4aE77E133b1EAB0A1C3Ed7cd8e69f",
  "0x12F7aD8b76e401461F53c9c8bCdefc40A2cffE6f",
  "0x86f2888c3983fD93bcbE616D5E252f82546dAAC0",
  "0xae287547Cf6E8eF3153a920e5e7744961ad9E384",
  "0x263157b41DaBF088B176c23731eF088DF39628D5",
  "0x5B1398841d3D8fb9f4350bc24C065f7bda27e350",
  "0x33c76ddC2c60b9B5Ee47Caf938D75379c294897a",
  "0x956DaAf6a53f31f8742ffA79643CAC713b394B9d",
  "0x6d2A565d2c3fD22897c1776371d05E13Cb674eB8",
  "0x483Fbd91CAd3BA156B62A987981B4a0585619681",
  "0x1D3542a0c5076305082772dfD54CA8e284c8B111",
  "0xb7cf5E3d3acB534c0B03cC4AEC09860F1dFcCD73",
  "0x30B1A244C550f5Eab2E9012D06334ca9AC0F2A92",
  "0xA0e4a3c6c09ad91ccb7A002067ef4c321eDa8F0E",
  "0x5b7870a1Ae6b0c9686502d5452D00969dE3a8180",
  "0x7B142abe1297BfD83Ad83f2D08a71C2520651eFB",
  "0xC31334734f11a29495736AB368Eb16FEcfC92c18",
  "0xcaf699075cEcaecCb12f88c7A31B469607CC1b6B",
  "0x58C2bf8873C72Cd10317673e76a6ec4443cfFe36",
  "0x62F6BEc901dF2409b1c52E10BC9902d17d73BFAF",
  "0x9829bb5FafCed0c47d4DD2f03fe9cc3a71F63003",
  "0x0E329117D00e7C45b6361d04BA6B189A7DB2617F",
  "0xE682E9E3c2A7b50717eCC43226E0d8804dCB31c6",
  "0xf6426603cDeb29f1e5dC3408856F56630f2D68Fa",
  "0x7FECE717af06BAa8EFe094A0ed2F4bA442082c40",
  "0x5B94c9912c16ECF6F6EBa1C3dc22e3b532FbD882",
  "0x3678226c124a06fB286FfA9E9462F2b662B4198C",
  "0x8885bf204cE4D12541d3b64C9507fFA9491D9013",
  "0x0Ed0E3fF8D08491a8aEe8C6b61BfDf1C4F17DD50",
  "0x959F7D2F45AE3c98D1C214Fe1c47CbbF2dCAb717",
  "0x6A59204e3fEE4fF5b20A8F82f14B9C2F8fca376E",
  "0x3541A3556721b657E2EFa8f9DfC9C1880C77961B",
  "0xbe8961f2Ec7D606bC3FA2E21490D80313cD2b8dF",
  "0xdee8B0932FDEc6BD7277E04c673d2d8667CB8608",
  "0xC47Daf3a830861E8782e62D9036382bE8c099be0",
  "0x7696cF6913E36131d58d9832EFb71F4853F667B4",
  "0x58b4b0da2740ab7b2070f9E6e89e8c195c44c464",
  "0x122570374B3AB12E2aa8e176cC8FDAeeD56C692a",
  "0x236917433Cda24e05702D5A235fE0930ea81770c",
  "0x7a9f16921665558f84d4eb416d65d5e261132ea8",
  "0xc5aCC90a28786FAf604CD1A1d3030bCf992c1de2",
  "0x69fF35A869f1FA97af6E5eef279F4474cb87ece1",
  "0x9a8569719304317fd7aBa7ECA3d52B02223ce684",
  "0x2AE55E77e9f15e5AaEBcAdbd77b93f10d01241F2",
  "0x52CA8910C58C28feDf4C45B6dCf0bdA7D7255F99",
  "0x1D44565D73dC4212C5A5b7117EE038b42f7eE3E9",
  "0xa11e25CbA933d5299916c0D5b98194e30B5d4356",
  "0x51f8906cE858143A0C5b29Fda0e76f96324489D4",
  "0xF301601C438ebc0DADe966a4F80875066a4275B7",
  "0x6EbEf7e988D4E37fb9903Ed7Fd907FF07978075A",
  "0xE5AF8FbbAa1794Bd0036f4F5A178391e8905e440",
  "0xAD6eaBCFC34D7099E1ac6F6e4d1aA11eA31c4B2B",
  //ADDITIONAL ROUND 2
  "0x42132EFbBb4C16D9ebFAE10dF3BBf141904A61BA",
  "0x75017a960755bDaf6b4D03b9A11B36E732d037C4",
  "0x365166f14C6ED9E3a842da93aAA1Cb903764B05a",
  "0xaab65302A1c4AED861049fdBE45053EAb1840257",
  "0x904AB4097476fA11BbEF56FfBfe83eeda09DDFeE",
  "0x5E871A8E28cd5996473e389D996c44965e91565d",
  "0xf3aA5914A37601dAa65bf7e3cfCCBFab9308eD8e",
  "0x22A7CF64260CFa7870Eb445fd342F64E279E64Db",
  "0x4c8032e4EE5f72AacB72C122E6353F4266Ac6888",
  "0x2443e849e726EF873938c55C3E4bcd2c5278216a",
  "0xe952406C9bb3a439300ffCA1dF618Feed45A8139",
  "0xDfda4d6F746E30a3fFB418920E5d150EE79d2E2B",
  "0x9A4BE97f396ad542B02Faf9C331f2b01FaaAd356",
  "0x3aac601CEC7dc4C4A6d69bc58a3E9e78EeCa39E5",
 ];

 const vestedWallets = [...new Set(vested)];

 const formatLocalTime = (timestamp) => {
  const date = new Date(timestamp * 1000); // convert timestamp to milliseconds
  const options = {
   year: "numeric",
   month: "short",
   day: "numeric",
   hour: "numeric",
   minute: "numeric",
   hour12: true,
   // timeZoneName: "short",
  };
  return date.toLocaleString(undefined, options);
 };

 const getOpeningTime = async () => {
  try {
   const contract = await ReadContract();

   const time = parseInt(await contract.launchTime());
   //  console.log("TIME", time);

   setOpeningTime((+time + 2592000) * 1000);
  } catch (err) {
   console.log(err);
  }
 };

 useEffect(() => {
  getOpeningTime();
 }, []);

 const teamWallet = [
  "0x86f2888c3983fD93bcbE616D5E252f82546dAAC0",
  "0xae287547Cf6E8eF3153a920e5e7744961ad9E384",
  "0x263157b41DaBF088B176c23731eF088DF39628D5",
  "0x5B1398841d3D8fb9f4350bc24C065f7bda27e350",
  "0x33c76ddC2c60b9B5Ee47Caf938D75379c294897a",
  "0x956DaAf6a53f31f8742ffA79643CAC713b394B9d",
  "0x6d2A565d2c3fD22897c1776371d05E13Cb674eB8",
  "0x483Fbd91CAd3BA156B62A987981B4a0585619681",
  "0x1D3542a0c5076305082772dfD54CA8e284c8B111",
 ];

 return (
  <div className="App">
   <div className="container-fluid p-0">
    {/* Navigation Menu */}
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark">
     <div className="container">
      {/* Logo and Title */}
      <a className="navbar-brand" href="#">
       <img
        src="https://modeland.ai/assets/images/modeland-logo.png"
        alt="Logo"
        height="50"
        className="me-2"
       />
      </a>
      {/* Navbar Toggler for Mobile */}
      <button
       className="navbar-toggler"
       type="button"
       data-bs-toggle="collapse"
       data-bs-target="#navbarNav"
       aria-controls="navbarNav"
       aria-expanded="false"
       aria-label="Toggle navigation"
      >
       <span className="navbar-toggler-icon"></span>
      </button>
      {/* Navbar Items */}
      <div className="collapse navbar-collapse" id="navbarNav">
       <ul className="navbar-nav ms-auto">
        {/* Connect Wallet Button */}
        <li className="nav-item">
         <w3m-button balance={"hide"} />
        </li>
       </ul>
      </div>
     </div>
    </nav>
   </div>

   {/* Two Column Layout */}
   <section
    style={{
     background: `url(${bg})`,
     backgroundRepeat: "no-repeat",
     marginTop: "-40px",
    }}
    className="py-5"
   >
    <div className="row m-md-5">
     {/* First Column */}
     <div className="mb-3 col-md-12 d-flex align-items-center justify-content-center">
      <div className="text-center bg-white p-2 rounded">
       {openingTime > time && (
        <>
         <h2 className="display-4" style={{ color: "red", textShadow: "2px 2px 4px #111" }}>
          Claim Starts:
         </h2>

         <CountdownTimer targetDate={openingTime} />
        </>
       )}

       {time > openingTime && (
        <>
         <h2 className="display-4" style={{ color: "red", textShadow: "2px 2px 4px #111" }}>
          Claim Ends
         </h2>
         <CountdownTimer targetDate={1771632000 * 1000} />
        </>
       )}
      </div>
     </div>
     {/* Second Column */}
     <div className="col-md-12">
      <div
       className="p-4 border border-3 border-white"
       style={{
        backgroundColor: "rgba(0, 0, 0, 0.6)",
        borderRadius: "10px",
       }}
      >
       <div className="row text-center">
        <div className="col-md-12 text-center">
         {loading && <Spinner className="my-5" name="circle" color="#f2bd48" />}
        </div>
       </div>
       {isConnected ? (
        <>
         <div className="d-flex justify-content-between mx-md-5 mb-2">
          <span>
           <b>Total tokens</b>
          </span>
          <span>{formatNumber(initialBalance)} 18+</span>
         </div>
         <div className="d-flex justify-content-between mx-md-5 mb-2">
          <span>
           <b>Total claimed</b>
          </span>
          <span>{formatNumber((+initialBalance - +currentBalance).toString())} 18+</span>
         </div>
         <div className="d-flex justify-content-between mx-md-5 mb-2">
          <span>
           <b>Current balance</b>
          </span>
          <span>{formatNumber(currentBalance)} 18+</span>
         </div>
         <div className="d-flex justify-content-between mx-md-5 mb-2">
          <span>
           <b>Claimable now</b>
          </span>
          <span>{formatNumber(claimableTokens)} 18+</span>
         </div>
         <div className="d-flex justify-content-between mx-md-5 mb-2">
          <span>
           <b>Last claim time</b>
          </span>
          <span>{+lastClaimTime > 0 ? formatLocalTime(lastClaimTime) : "No claims yet"}</span>
         </div>
         {/* Two Column Layout */}

         <p></p>

         <p></p>

         {isConnected && time > openingTime && canClaim && +claimableTokens > 0 && (
          <div className="col-md-12">
           <button className="btn btn-danger" onClick={handleClaimToken}>
            Claim Tokens
           </button>
          </div>
         )}
        </>
       ) : (
        <div className="text-center">
         PLEASE CONNECT TO A WALLET FIRST.
         <p></p>
         <w3m-button balance={"hide"} />
        </div>
       )}
      </div>
     </div>
    </div>
   </section>

   <section
    style={{
     background: `url(${faq})`,
     backgroundRepeat: "no-repeat",
     marginTop: "-40px",
     backgroundAttachment: "scroll",
     backgroundSize: "cover",
    }}
    className="py-5"
    id="about"
   >
    <div className="container">
     <div className="row">
      <div className="col-md-2"></div>
      <div className="col-md-8" style={{ fontWeight: "bold" }}>
       <h2 className="display-4" style={{ color: "white", textShadow: "2px 2px 4px red" }}>
        CHECK VESTING
       </h2>
       <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-12 p-4" style={{ backgroundColor: "#fff", color: "#fff" }}>
         <div style={{ display: "flex", gap: 10, width: "100%", alignContent: "center" }}>
          <input
           placeholder="Paste a wallet to search"
           type="search"
           className="w-100"
           style={{ color: "#111", height: "45px" }}
           onInput={(e) => setWallet(e.target.value)}
          />
          <button className="btn btn-danger" onClick={() => fetchVesting(wallet)}>
           <svg
            width="30px"
            height="30px"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
           >
            <path
             fill-rule="evenodd"
             clip-rule="evenodd"
             d="M15 10.5C15 12.9853 12.9853 15 10.5 15C8.01472 15 6 12.9853 6 10.5C6 8.01472 8.01472 6 10.5 6C12.9853 6 15 8.01472 15 10.5ZM14.1793 15.2399C13.1632 16.0297 11.8865 16.5 10.5 16.5C7.18629 16.5 4.5 13.8137 4.5 10.5C4.5 7.18629 7.18629 4.5 10.5 4.5C13.8137 4.5 16.5 7.18629 16.5 10.5C16.5 11.8865 16.0297 13.1632 15.2399 14.1792L20.0304 18.9697L18.9697 20.0303L14.1793 15.2399Z"
             fill="#ffffff"
            />
           </svg>
          </button>
         </div>
         <div className="row">
          <div className="col-md-12 text-center">
           {loading && <Spinner className="my-5" name="circle" color="#ff0000" />}
          </div>
          {Object.keys(vestInfo).length > 0 && (
           <div className="col-12 mt-3" style={{ color: "#111" }}>
            <div className="d-flex justify-content-between mx-md-5 mb-2">
             <span>
              <b>Total tokens</b>
             </span>
             <span className="text-danger">{formatNumber(vestInfo.initialBalance)} 18+</span>
            </div>
            <div className="d-flex justify-content-between mx-md-5 mb-2">
             <span>
              <b>Total claimed</b>
             </span>
             <span className="text-danger">
              {formatNumber((+vestInfo.initialBalance - +vestInfo.currentBalance).toString())} 18+
             </span>
            </div>
            <div className="d-flex justify-content-between mx-md-5 mb-2">
             <span>
              <b>Current balance</b>
             </span>
             <span className="text-danger">{formatNumber(vestInfo.currentBalance)} 18+</span>
            </div>
            <div className="d-flex justify-content-between mx-md-5 mb-2">
             <span>
              <b>Claimable now</b>
             </span>
             <span className="text-danger">{formatNumber(vestInfo.claimableTokens)} 18+</span>
            </div>
            <div className="d-flex justify-content-between mx-md-5 mb-2">
             <span>
              <b>Last claim time</b>
             </span>
             <span className="text-danger">
              {+vestInfo.lastClaimTime > 0
               ? formatLocalTime(vestInfo.lastClaimTime)
               : "No claims yet"}
             </span>
            </div>
           </div>
          )}
         </div>
        </div>
        <div className="col-md-1"></div>
       </div>
      </div>
      <div className="col-md-2"></div>
     </div>
    </div>
   </section>
  </div>
 );
}

export default Presale;
